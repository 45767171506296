/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 14/10/2022.
 */

//
// this is to be able to implement the same functionality in both the gatsby-ssr and the gatsby-browser
//

import React from 'react'
import { Script } from 'gatsby'
import { DEBUG } from './src/constant'

console.log(' gatsby-shared > DEBUG = ', DEBUG, typeof DEBUG)

export const wrapPageElement = ({ element }) => {
  return (
    <>
      {element}

      {DEBUG ? null : (
        <Script id="mouseflow-script" type="text/javascript">{`        
          window._mfq = window._mfq || [];
           (function() {
              var mf = document.createElement("script");
              mf.type = "text/javascript"; mf.defer = true;
              mf.src = "//cdn.mouseflow.com/projects/49e460a9-bf08-44ed-833e-c2dd1258b302.js";
              document.getElementsByTagName("head")[0].appendChild(mf);
            })();
      `}</Script>
      )}
    </>
  )
}

/*
<script type="text/javascript">
  window._mfq = window._mfq || [];
  (function() {
  var mf = document.createElement("script");
  mf.type = "text/javascript"; mf.defer = true;
  mf.src = "//cdn.mouseflow.com/projects/6b8cd350-a469-4d8a-9f60-9b98cb7ae691.js";
  document.getElementsByTagName("head")[0].appendChild(mf);
})();
</script>*/
