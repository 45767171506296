export const BASE_URL = process.env.GATSBY_BASE_URL
export const QUALIFICATION_URL = process.env.GATSBY_QUALIFICATION_URL
export const GATSBY_MAPBOX_API_KEY = process.env.GATSBY_MAPBOX_API_KEY
export const DEBUG = process.env.GATSBY_DEBUG === 'true'
export const PROJECT_REF = process.env.GATSBY_PROJECT_REF
export const GATSBY_IP_LOCATION = process.env.GATSBY_IP_LOCATION
export const UTM_KEY = process.env.GATSBY_UTM_KEY
export const PROJECT_DISPLAY_TITLE = process.env.GATSBY_PROJECT_DISPLAY_TITLE

export const PRIMARY_COLOR = '#121533'

export const CONTACT = {
  email: 'connect@studiesandme.com',
  address: 'Hauser Pl. 20, 1',
  zip: 'DK-1127',
  city: 'Copenhagen K',
  country: 'Denmark',
}
